var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "authWrapper"
  }, [_c('div', {
    staticClass: "container d-flex justify-content-center error-contianer"
  }, [_c('div', {
    staticClass: "row whiteBG-wrap p-2"
  }, [_c('div', {
    staticClass: "col-lg-12 col-md-12 "
  }, [_c('div', {
    staticClass: "right-bg-wrapper text-center"
  }, [_c('img', {
    staticClass: "invalid-icon",
    attrs: {
      "src": require("../../../assets/images/invalid.svg")
    }
  }), _c('br'), _c('span', {
    staticClass: "invalid-client"
  }, [_vm._v("Invalid Client ")]), _c('p', {
    staticClass: "info-error"
  }, [_vm._v(" Please ask your service provider to fix this issue ")]), _c('a', {
    attrs: {
      "href": "/"
    }
  }, [_c('button', {
    staticClass: "submit-btn form-control btn-back",
    on: {
      "click": function click($event) {
        return _vm.setConsent(true);
      }
    }
  }, [_vm._v(" Go Back ")])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }