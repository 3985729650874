<template>
  <div class="authWrapper">
    <div class="container">
      <div class="row whiteBG-wrap">
        <div class="col-lg-6 col-md-6 no-padding">
          <div class="left-bg-wrapper text-center">
            <h2>Welcome to all new Representative Dashboard</h2>
            <p>
              Experience the ease of managing your broker reps, members, and
              employer groups all in one place. Real-time data all available at
              a glance with our integrated platform.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="auth-form-wrap login-form">
            <div class="auth-form">
              <h2>Log In</h2>
              <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <form @submit.stop.prevent="handleSubmit(handleLogin)">
                  <input-text
                    :rules="{ required: true, email: true }"
                    labelFor="username"
                    labelName="Username"
                    inputName="username"
                    formGroupClass="form-group mb-3"
                    placeholder="Enter your username"
                    :vmodel.sync="form.username"
                  />

                  <input-text
                    :rules="{ required: true }"
                    labelFor="password"
                    labelName="Password"
                    inputName="username"
                    inputType='password'
                    formGroupClass="form-group mb-3"
                    placeholder="Enter your password"
                    :vmodel.sync="form.password"
                  />

                  <div class="error_message" v-if="formErrorFlag">
                    {{ formErrors["error"] }}
                  </div>

                  <div class="form-group mb-4">
                    <button type="submit" class="submit-btn">
                      <b-spinner v-if="loginLoader" small></b-spinner>
                      Login
                    </button>
                  </div>
                </form>
              </ValidationObserver>
              <router-link to="/forgot-password" class="pw-reset-btn" target="_blank"
                >Forgot Password?</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import helper from "@/resource/Helper";
import store from "@/store";

export default {
  name: "name",
  data() {
    return {
      form: {
        username: "",
        password: "",
        deviceID: "",
      },
      loginLoader: false,
      formErrorFlag: false,
      formErrors: [],
    };
  },
  mounted() {
    let uniqueDeviceId = this.$store.getters.deviceId
      ? this.$store.getters.deviceId
      : this.$uuid.v1();
    this.form.deviceID = uniqueDeviceId;
    this.$store.commit("setDeviceId", uniqueDeviceId);
  },
  methods: {
    handleLogin() {
      this.$store.commit("setAuthentication", false);
      this.loginLoader = true;
      this.$store.commit("setRepEmail", this.form.username);
      let app = this;
      axios
        .post(this.$endpoint.API_LOGIN_URL, {
          email: this.form.username,
          password: this.form.password,
          device_id: this.form.deviceID,
        })
        .then((response) => {
          if (response.data.status == "success") {
            store.commit("setAuthentication", true);
            helper.setLoginData(response.data);
            this.$emit('authorize');
          }
        })
        .catch(function(error) {
          if (error.response.data.status == "error") {
            if (error.response.data.message == "Your device is not verified") {
              app.sendOtpCode();
            } else {
              app.loginLoader = false;
              setTimeout(() => {
                app.formErrorFlag = false;
                app.formErrors = [];
              }, 4000);
            }
            helper.infoMessage(
              app,
              error.response.data.status,
              error.response.data.message
            );
          }
        });
    },
    sendOtpCode() {
      let app = this;
      let url =  this.$endpoint.API_SEND_LOGIN_OTP;
      axios
        .post(url, {
          email: this.form.username,
          data: "both",
          device_id: this.form.deviceID,
        })
        .then((response) => {
          this.$parent.otpverification = true;
          this.$parent.showLogin = false;
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
           // helper.delayRedirect("OtpVerification", 0);
          }
        })
        .catch(function(error) {
          if (error.response.data.status == "error") {
            helper.infoMessage(
              app,
              error.response.data.status,
              error.response.data.message
            );
          }
          app.loginLoader = false;
        });
    },
  },
};
</script>
<style src="@/assets/css/authen.css" scoped></style>
<style>
#label-username,
#label-password {
  font-size: 16px !important;
  color: #909090 !important;
  margin-bottom: 10px !important;
}
#username,
#password {
  font-size: 14px !important;
  padding: 12px 15px !important;
}
</style>
