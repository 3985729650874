<template>
    <div class="authWrapper">
        <div class="container">
            <div class="row whiteBG-wrap">
                <div class="col-lg-6 col-md-6 no-padding">
                    <div class="left-bg-wrapper text-center">
                        <h1>Verify your OTP</h1>
                        <p>Please enter 4 digits OTP code you received in your email and phone.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="auth-form-wrap">
                        <div class="auth-form">
                            <h2>Enter OTP</h2>
                            <div class="otp-form mb-5">
                                <v-otp-input
                                    @on-complete="onChangeOTP"
                                    ref="otpInput"
                                    :input-classes="errorClass +' otp-input'"
                                    separator=""
                                    :num-inputs="4"
                                    :should-auto-focus="true"
                                    :is-input-num="true"

                                />
                            </div>
                            <button @click="verifyToken" type="submit" class="submit-btn">
                                   <b-spinner v-if="formLoader" small></b-spinner>
                                Verify Code</button>
                            <OTPTimer title="Didn't get Code? Resend Code" @sendOTP=sendOtpCode :expirySecond=60 />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/*eslint-disable*/
import { mapGetters } from "vuex";
import axios from "axios";
import helper from "@/resource/Helper";
import OTPTimer from "@/components/ui/OTPTimer";
export default {
    name: 'OtpVerification',
    components: {
        OTPTimer
    },
    data () {
        return {
            otp:'',
            formLoader: false,
            formErrorFlag: false,
            formErrors: [],
            errorClass : ''
            
        }
    },
    computed: {
    ...mapGetters({
      deviceId: "deviceId",
      email: "repEmail",
    }),
  },
    methods: {
    verifyToken() {
      this.formLoader = true
      this.errorClass = false;
      axios
        .post(this.$endpoint.API_VALIDATE_LOGIN_OTP, 
        {
          email: this.email,
          data: "both",
          user_type: "A",
          device_id: this.deviceId,
          verification_code: this.otp,
        })
        .then((response) => {
          if (response.data.status == "success") {
            helper.setLoginData(response.data);
             this.$emit('authorize');
             this.$parent.otpverification = false;
             this.$parent.consent = true;
          }
        })
        .catch((error) => {
             this.errorClass = 'highlight_otp'
          if (error.response.data.status == "error") {
              helper.infoMessage(
              this,
              error.response.data.status,
              error.response.data.message
            );
          }
          this.verifyLoader = false;
          
        }).finally(() => {
            this.formLoader = false;
        } )
    },
    sendOtpCode() {
      let app = this;
      let url =  this.$endpoint.API_SEND_LOGIN_OTP;
      axios
        .post(url, {
          email: this.email,
          data: "both",
          device_id: this.deviceId,
        })
        .then((response) => {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
          }
        })
        .catch(function(error) {
          if (error.response.data.status == "error") {
            helper.infoMessage(
              app,
              error.response.data.status,
              error.response.data.message
            );
          }
        });
    },
    onChangeOTP(otp) {
       this.otp = otp
    }
    }
}
</script>
<style src="@/assets/css/authen.css"></style>
<style>
.highlight_otp {
  border: 2px solid red !important;
}
</style>