<template>
  <div>
    <OAuthLogin v-if="showLogin" @authorize="authorize" />
    <CLientError v-if="clientError" />
    <Consent v-if="consent" @takeConsent="takeConsent"  :authroizedData="authroizedData"/>
    <OAuthOTPVerification v-if="otpverification" @authorize="authorize" />
  </div>
</template>
<script>
/*eslint-disable*/
import axios from "axios";
import OAuthLogin from "@/views/OAuth/partails/OAuthLogin.vue";
import CLientError from "@/views/OAuth/partails/ClientError.vue";
import Consent from "@/views/OAuth/partails/Consent.vue";
import OAuthOTPVerification from "@/views/OAuth/partails/OAuthOtpVerification.vue";
import { mapGetters } from "vuex";

export default {
  name: "OAuth",
  components: {
    OAuthLogin,
    CLientError,
    Consent,
    OAuthOTPVerification,
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
        deviceID: "",
      },
      loginLoader: false,
      formErrorFlag: false,
      formErrors: [],
      authorizeParam: "",
      showLogin: false,
      clientError: false,
      consent: false,
      otpverification: false,
      authroizedData: {},
    };
  },
  computed: {
    ...mapGetters({
      accessToken: "accessToken",
    }),
  },
  mounted() {
    this.authorize();
  },
  methods: {
    authorize() {
      const uninterceptedAxiosInstance = axios.create();
      uninterceptedAxiosInstance
        .get(
          this.$endpoint.OAUTH_AUTHORIZE +
            "?client_id=" +
            this.getUrlParams().client_id +
            "&redirect_uri=" +
            this.getUrlParams().redirect_url,
          {
            headers: {
              Authorization: "Bearer " + this.accessToken,
            },
          }
        )
        .then((response) => {
          this.authroizedData = response.data.data;
          this.consent = true;
        })
        .catch((error) => {
          this.showLogin = [401, 418].includes(error.response.status);
          this.clientError = [403, 422].includes(error.response.status);
        });
    },

    takeConsent(consent) {

      if(!consent){
        this.$router.push({ name: 'Dashboard' })
        return;
      }
      if (
        consent &&
        this.authroizedData.redirect_uri == this.getUrlParams().redirect_url
      ) {
        let url =
          this.authroizedData.redirect_uri +
          "?code=" +
          this.authroizedData.code +
          "&state=" +
          this.getUrlParams().state;
        window.location = url;
      } else {
        this.clientError = true;
      }
    },

    getUrlParams() {
      let params = new URL(location.href).searchParams;
      return {
        client_id: params.get("client_id"),
        redirect_url: params.get("redirect_uri"),
        state: params.get("state"),
      };
    },
  },
};
</script>
<style src="@/assets/css/authen.css" scoped></style>
<style>
#label-username,
#label-password {
  font-size: 16px !important;
  color: #909090 !important;
  margin-bottom: 10px !important;
}
#username,
#password {
  font-size: 14px !important;
  padding: 12px 15px !important;
}
</style>
