var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', {
    staticClass: "resend-otp resend_otp",
    class: _vm.remaningSecond ? 'disable-otp' : '',
    on: {
      "click": _vm.sendOtpCode
    }
  }, [_vm._v(" " + _vm._s(_vm.getMessage(_vm.remaningSecond)) + " ")]), _vm.remaningSecond ? _c('div', [_c('div', {
    staticClass: "progress",
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c('div', {
    staticClass: "progress-bar bg-success",
    style: {
      width: _vm.remainingPercentage + '%'
    },
    attrs: {
      "role": "progressbar",
      "aria-valuenow": "60",
      "aria-valuemin": "0",
      "aria-valuemax": "100"
    }
  })])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }