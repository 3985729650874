<template>
  <div class="authWrapper">
    <div class="container d-flex justify-content-center error-contianer">
      <div class="row whiteBG-wrap p-2">
        <div class="col-lg-12 col-md-12 ">
          <div class="right-bg-wrapper text-center">
            <img src="../../../assets/images/invalid.svg" class="invalid-icon" /><br>
            <span class="invalid-client">Invalid Client </span>
            <p class="info-error">
              Please ask your service provider to fix this issue
            </p>
              <a href="/" >
            <button
              class="submit-btn form-control btn-back"
              @click="setConsent(true)"
            >
             Go Back
            </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ClientError"
};
</script>
<style src="@/assets/css/authen.css" scoped></style>
<style>
#label-username,
#label-password {
  font-size: 16px !important;
  color: #909090 !important;
  margin-bottom: 10px !important;
}
#username,
#password {
  font-size: 14px !important;
  padding: 12px 15px !important;
}
</style>
