var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "authWrapper"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row whiteBG-wrap"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-lg-6 col-md-6"
  }, [_c('div', {
    staticClass: "auth-form-wrap login-form"
  }, [_c('div', {
    staticClass: "auth-form"
  }, [_c('h2', [_vm._v("Log In")]), _c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.stopPropagation();
              $event.preventDefault();
              return handleSubmit(_vm.handleLogin);
            }
          }
        }, [_c('input-text', {
          attrs: {
            "rules": {
              required: true,
              email: true
            },
            "labelFor": "username",
            "labelName": "Username",
            "inputName": "username",
            "formGroupClass": "form-group mb-3",
            "placeholder": "Enter your username",
            "vmodel": _vm.form.username
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "username", $event);
            }
          }
        }), _c('input-text', {
          attrs: {
            "rules": {
              required: true
            },
            "labelFor": "password",
            "labelName": "Password",
            "inputName": "username",
            "inputType": "password",
            "formGroupClass": "form-group mb-3",
            "placeholder": "Enter your password",
            "vmodel": _vm.form.password
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "password", $event);
            }
          }
        }), _vm.formErrorFlag ? _c('div', {
          staticClass: "error_message"
        }, [_vm._v(" " + _vm._s(_vm.formErrors["error"]) + " ")]) : _vm._e(), _c('div', {
          staticClass: "form-group mb-4"
        }, [_c('button', {
          staticClass: "submit-btn",
          attrs: {
            "type": "submit"
          }
        }, [_vm.loginLoader ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _vm._e(), _vm._v(" Login ")], 1)])], 1)];
      }
    }])
  }), _c('router-link', {
    staticClass: "pw-reset-btn",
    attrs: {
      "to": "/forgot-password",
      "target": "_blank"
    }
  }, [_vm._v("Forgot Password?")])], 1)])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-6 col-md-6 no-padding"
  }, [_c('div', {
    staticClass: "left-bg-wrapper text-center"
  }, [_c('h2', [_vm._v("Welcome to all new Representative Dashboard")]), _c('p', [_vm._v(" Experience the ease of managing your broker reps, members, and employer groups all in one place. Real-time data all available at a glance with our integrated platform. ")])])]);
}]

export { render, staticRenderFns }