var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "authWrapper "
  }, [_c('div', {
    staticClass: "container d-flex justify-content-center main-container"
  }, [_c('div', {
    staticClass: "row whiteBG-wrap p-3"
  }, [_c('div', {
    staticClass: "col-lg-12 col-md-12 "
  }, [_c('div', {
    staticClass: "right-bg-wrapper text-center"
  }, [_c('img', {
    staticClass: "calender-icon mt-2 mb-2",
    attrs: {
      "src": _vm.authroizedData.logo
    }
  }), _c('p', {
    staticClass: "desc-deatail"
  }, [_c('b', [_c('span', [_vm._v(_vm._s(_vm.authroizedData.service_name))])]), _vm._v(" app wants to access your rep account.")]), _c('p', {
    staticClass: "desc-deatail"
  }, [_vm._v("This will allow "), _c('span', [_vm._v(_vm._s(_vm.authroizedData.service_name))]), _vm._v(" app to ")]), _c('p', {
    staticClass: "mb-3"
  }, [_c('b', [_vm._v(_vm._s(_vm.authroizedData.tagline ? _vm.authroizedData.tagline : 'Access your personal info'))])])]), _c('div', {
    staticClass: "form-group mb-3 ml-4 d-flex action-btn"
  }, [_c('a', {
    staticClass: "a-btn-allow",
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_c('button', {
    staticClass: "submit-btn form-control btn-allow",
    on: {
      "click": function click($event) {
        return _vm.setConsent(true);
      }
    }
  }, [_vm._v(" Allow ")])]), _c('a', {
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_c('button', {
    staticClass: "submit-btn form-control btn-cancel",
    attrs: {
      "id": "cancel-consent"
    },
    on: {
      "click": function click($event) {
        return _vm.setConsent(false);
      }
    }
  }, [_vm._v(" Cancel ")])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }