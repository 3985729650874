<template>
  <div class="authWrapper ">
    <div class="container d-flex justify-content-center main-container" >
      <div class="row whiteBG-wrap p-3" >
        <div class="col-lg-12 col-md-12 ">
          <div class="right-bg-wrapper text-center">
            <img :src="authroizedData.logo" class="calender-icon mt-2 mb-2" />
            <p class="desc-deatail"><b><span>{{authroizedData.service_name}}</span></b> app wants to access your rep account.</p>
            <p class="desc-deatail">This will allow <span>{{authroizedData.service_name}}</span> app to </p>
            <p class="mb-3"><b>{{authroizedData.tagline ? authroizedData.tagline : 'Access your personal info'}}</b></p>
          </div>
          <div class="form-group mb-3 ml-4 d-flex action-btn">
            <a href="javascript:void(0)" class="a-btn-allow">
            <button
              class="submit-btn form-control btn-allow"
              @click="setConsent(true)"
            >
              Allow
            </button>
            </a>
            <a href="javascript:void(0)" >
              <button
                class="submit-btn form-control btn-cancel"
                id="cancel-consent"
                @click="setConsent(false)"
              >
                Cancel
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Consent",
  props: {
    authroizedData: {
      type: Object
    }
  },
  data () {
    return {
      routeClientId: null
    }
  },
  methods: {
    setConsent(val) {
      this.$emit("takeConsent", val);
    }
  }
};
</script>
<style src="@/assets/css/authen.css" scoped></style>
<style>
#label-username,
#label-password {
  font-size: 16px !important;
  color: #909090 !important;
  margin-bottom: 10px !important;
}
#username,
#password {
  font-size: 14px !important;
  padding: 12px 15px !important;
}
</style>
