var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showLogin ? _c('OAuthLogin', {
    on: {
      "authorize": _vm.authorize
    }
  }) : _vm._e(), _vm.clientError ? _c('CLientError') : _vm._e(), _vm.consent ? _c('Consent', {
    attrs: {
      "authroizedData": _vm.authroizedData
    },
    on: {
      "takeConsent": _vm.takeConsent
    }
  }) : _vm._e(), _vm.otpverification ? _c('OAuthOTPVerification', {
    on: {
      "authorize": _vm.authorize
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }